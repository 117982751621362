<template>
    <section class="content">
    <div class="card card-info">
        <div class="card-header">
            <div class="box-tools card-tools toolbar">
            <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-plus"></i> Buat Jurnal
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                <router-link to="/report/form-jurnal" class="dropdown-item">
                <button type="button" class="btn btn-sm dropdown-item" > 
                        <i class="fas fa-edit"></i> Input Manual
                </button>
                </router-link>
                </div>
            </div>
            </div>
        </div>
        <div class="card-body table-full">
        <div class="table-responsivex table-ordergroup">
            <table class="table table-hover" ref="tbljurnalgroup">
            <thead>
                <tr>
                <th>TANGGAL</th>
                <th>NO. JURNAL</th>
                <th>REFERENSI</th>
                <th>JURNAL TYPE</th>
                <th>NOTE</th>
                <th>TINDAKAN</th>
                </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
            </table>
        </div>
        </div>
    </div>
    </section>
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "JurnalGroup",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      groups: [],
      formTitle: "Tambah",
      form: {
        courier_id: "",
        kode: "",
        nama: "",
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
        if (e.target.closest("button")) {
            if (e.target.dataset.action == "edit") {
                let id = e.target.dataset.id;
                this.$router.push({ path: `/report/form-jurnal/${id}`});
            } else if (e.target.dataset.action == "balik") {
                let id = e.target.dataset.id;
                this.$router.push({ path: `/report/form-jurnal/balik/${id}`});
            } else if (e.target.dataset.action == "duplikat") {
                let id = e.target.dataset.id;
                this.$router.push({ path: `/report/form-jurnal/duplikat/${id}`});
            } else if (e.target.dataset.action == "hapus") {
                Swal.fire({
                title: "Hapus data?",
                icon: "question",
                denyButtonText: '<i class="fa fa-times"></i> Hapus',
                showCancelButton: true,
                showDenyButton: true,
                showConfirmButton: false,
                }).then((result) => {
                if (result.isDenied) {
                    authFetch("/accounting/jurnal/group/" + e.target.dataset.id, {
                    method: "DELETE",
                    body: "id=" + e.target.dataset.id,
                    })
                    .then((res) => {
                        return res.json();
                    })
                    .then((js) => {
                        this.tbl.api().ajax.reload();
                    });
                }
                });
            }
        }
    },
},
mounted() {
const e = this.$refs;
var self = this;

this.tbl = createTable(e.tbljurnalgroup, {
    title: "",
    roles: this.$route.params.roles,
    ajax: "/accounting/jurnal/group",
    processing: true,
    selectedRowClass: "",
    language: {
    processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
    columns: [
    { data: "journal_date" },
    { data: "journal_no" },
    { data: "referensi" },
    { data: "jurnal_type" },
    { data: "note" },
    { data: "action", sortable: false,
    render: function (data, type, row, meta) {
        let dropdown =
        `<div class="btn-group">
        <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Klik
        </button>
        <div class="dropdown-menu dropdown-menu-right">
        <button type="button" class="btn btn-sm btn-warning dropdown-item" data-action="edit" data-id="` +
                row.id +
                `"> Edit </button>
        <button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="balik" data-id="` +
                row.id +
                `"> Jurnal Balik </button>
        <button type="button" class="btn btn-sm btn-danger dropdown-item" data-action="duplikat" data-id="` +
                row.id +
                `"> Duplikat Jurnal </button>
        </div>
        </div>`;

        return dropdown;
    }, },
    ],
    filterBy: [1,2,4],
    rowCallback: function (row, data) {},
    initComplete: function () {
        $('.loading-overlay').removeClass('show');
    },
    });
},
};
</script>